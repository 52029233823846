<template>
  <div>
    <h4>Profile</h4>
    <hr class="my-4" />
    <form class="mb-4" id="registration-form" @submit.prevent="handleSubmit">
      <b-row>
        <b-col lg="5">
          <b-form-group>
            <label for="firstName"><small>First Name</small> </label>
            <b-form-input
              v-model="userCopy.firstName"
              required
              id="firstName"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group>
            <label for="lasttName"><small>Last Name</small> </label>
            <b-form-input
              required
              v-model="userCopy.lastName"
              id="lasttName"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group>
            <label for="mi"><small>MI</small> </label>
            <b-form-input required v-model="userCopy.mi" id="mi"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label><small>Name of Spouse</small></label>
            <b-form-input v-model="userCopy.nameOfSpouse"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="dateOfBirth"><small>Date of Birth</small> </label>
            <b-form-input
              required
              v-model="userCopy.dateOfBirth"
              type="date"
              id="dateOfBirth"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <label><small>Sex</small></label>
            <b-form-select
              v-model="userCopy.sex"
              :options="sexOptions"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <label><small>Civil Status</small></label>
            <b-form-input v-model="userCopy.civilStatus"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <label for="address"><small>Complete Address</small> </label>
        <b-form-input
          required
          v-model="userCopy.address"
          id="address"
        ></b-form-input>
      </b-form-group>

      <b-row>
        <b-col lg="5">
          <b-form-group>
            <label><small>House / Building No.</small></label>
            <b-form-input v-model="userCopy.buildingNo"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group>
            <label><small>Building Name</small></label>
            <b-form-input v-model="userCopy.buildingName"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group>
            <label><small>Unit No.</small></label>
            <b-form-input v-model="userCopy.unitNo"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <label><small>Street</small></label>
            <b-form-input v-model="userCopy.street"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <label><small>Barangay</small></label>
            <b-form-input v-model="userCopy.barangay"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4">
          <b-form-group>
            <label><small>Subdivision</small></label>
            <b-form-input v-model="userCopy.subdivision"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <label><small>Town / City</small></label>
            <b-form-input v-model="userCopy.town"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group>
            <label><small>Province</small></label>
            <b-form-input v-model="userCopy.province"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group>
            <label for="contactNo"><small>Contact No.</small> </label>
            <b-form-input
              required
              v-model="userCopy.contactNo"
              type="tel"
              id="contactNo"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group>
            <label for="email"><small>Email</small> </label>
            <b-form-input
              v-model="userCopy.email"
              required
              type="email"
              id="email"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button class="mt-2" type="submit" variant="primary" size="sm">
        Update Profile
      </b-button>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'UserFormBio',

    props: {
      user: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        userCopy: { ...this.user },
        sexOptions: [
          { value: null, text: 'Please select an option' },
          { value: 'Male', text: 'Male' },
          { value: 'Female', text: 'Female' }
        ]
      }
    },

    watch: {
      user: {
        deep: true,
        handler: 'copyProps'
      }
    },

    methods: {
      copyProps () {
        this.userCopy = { ...this.user }
      },

      handleSubmit () {
        this.$emit('submitting', this.userCopy)
      }
    }
  }
</script>

<style>
</style>
