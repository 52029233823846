<template>
  <b-container>
    <b-row class="justify-content-center align-items-center">
      <b-col lg="12">
        <h4 class="page-title">Edit User</h4>
        <b-card>
          <user-form-bio
            :user="user"
            @submitting="updateProfile"
          ></user-form-bio>
          <user-form-security
            :credentials="credentials"
            @submitting="updatePassword"
          >
            <template v-slot:header>
              <h4 class="pt-4">Account Security</h4>
              <hr class="my-4" />
            </template>
            <template v-slot:footer>
              <b-button type="submit" variant="primary" size="sm">
                Update Password
              </b-button>
            </template>
          </user-form-security>
        </b-card>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import UserFormBio from '@/components/UserFormBio.vue'
  import UserFormSecurity from '@/components/UserFormSecurity.vue'

  export default {
    name: 'UserEditor',

    components: { UserFormBio, UserFormSecurity },

    data () {
      return {
        user: {},
        credentials: {
          newPassword: '',
          confirmPassword: ''
        }
      }
    },

    methods: {
      async loadUser () {
        const { id } = this.$store.state.user

        this.$refs.spinner.show()

        try {
          this.user = await UserService.findById(id)
          // Hide spinner
          this.$refs.spinner.hide()
        } catch (error) {
          // Hide spinner
          this.$refs.spinner.hide()
          // Show error message
          this.$refs.toast.show({
            type: 'error',
            message: error.response.data.message
          })
        }
      },

      async updateProfile () {
        this.$refs.spinner.show()

        try {
          // Update user
          this.user = await UserService.save(this.user)
          // Show spinner
          this.$refs.spinner.hide()
          // Show success message
          this.$refs.toast.show({
            type: 'success',
            message: 'User profile is updated successfully.'
          })
        } catch (error) {
          // Hide spinner
          this.$refs.spinner.hide()
          // Show error message
          this.$refs.toast.show({
            type: 'error',
            message: error.response.data.message
          })
        }
      },

      async updatePassword () {
        if (this.newPassword !== this.confirmPassword) {
          this.error = 'Password does not match!.'
          return
        }

        this.$refs.spinner.show()

        try {
          await UserService.updatePassword({
            id: this.$store.state.user.id,
            password: this.newPassword
          })

          this.$refs.spinner.hide()
          this.$router.push({ name: 'AuthLogin' })
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      }
    },

    mounted () {
      this.loadUser()
    }
  }
</script>

<style>
</style>
